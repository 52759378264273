.corner-box-menu {
	margin-top: 2em;
	.corner-box-menu-item {
		color: @mainGray;
		transition: @defaultTransition;
		text-decoration: none;
		max-width: 280px;
		position: relative;
		padding-right: 1.5em;
		&:last-child {
			padding-right: 0;
		}
		&:hover, &:focus {
			color: @highlightGray;
			h2, h3 {
				color: @highlightGray;
			}
			.corner-box-menu-corner {
				border-width: 4px;
				border-color: @mainRed;
			}
		}
		@media @min-sm {
			max-width: none;
			display: block;
			margin-bottom: 2em;
		}
		@media @max-xs {
			max-width: none;
			display: block;
			margin:0 1.5em 2em 1.5em;
		}
		.corner-box-menu-corner {
			position: absolute;
			top: -14px;
			left: -4px;
			width: 40px;
			height: 40px;
			border: 2px solid @splitGray;
			border-bottom: none;
			border-right: none;
			transition: @defaultTransition;
		}
	}
}

.box-menu {

	a:not(.btn) {
		outline: none;
		color: @mainGray;
	}
	h2 {
		margin-bottom: 1em;
	}
	.box-menu-archive {
		text-decoration: underline;
		color: @mainRed;
		&:hover, &:focus {
			text-decoration: none;
		}
	}
	.box-menu-item {
		display: block;
		color: @mainGray;
		border-bottom: 1px solid @backgroundGray;
		padding-bottom: 0.4em;
		margin-bottom: 1.2em;
		&:hover, &:focus {
			color: @mainGray;
			text-decoration: none;
			h2, h3 {
				text-decoration: underline;
			}
		}
		h3 {
			color: @highlightGray;
			font-size:1.3em;
		}
		.box-menu-meta-info {
			font-style: normal;
			font-size: 0.9em;
			color: @softGray;
			margin: 0.8em 0;
		}
	}

	&.box-menu-red {

		.box-menu-item {
			h2, h3 {
				color: @mainRed;
				position: relative;
				font-size: 1.3em;
				&::after {
					font-family: FontAwesome;
					position: absolute;
					font-size: 0.8em;
					right: 0;
					top: 0;
					content: "\f054";
				}
			}
		}

		.box-menu-closed {
			.box-menu-detail {
				display: none;
			}
			.box-menu-item {
				h2, h3 {
					&::after {
						content: "\f078";
					}
				}
			}
		}
		.box-menu-open {
			.box-menu-item {
				h2, h3 {
					&::after {
						content: "\f077";
					}
				}
			}
		}
	}
}