.about-description {
	p {
		font-size: 18px;
	}
}

.about-contact {
	h2 {
		font-size: 1.5rem;
	}
	h3 {
		font-size: 1.3rem;
	}
	p {
		font-size: 20px;
		i.fa {
			float: left;
			margin:4px 8px 0 0;
			&.fa-home {
				margin-bottom: 20px;
			}
		}
		&.about-contact-billing {
			font-size: 18px;
		}
	}
}

.about-employees {
	.about-employee {
		h3 {
			font-size: 20px;
		}
	}
}

.about-map {
	iframe {
		border: 0;
		width: 100%;
		height: 100%;
		min-height: 250px;
	}
}