section.intro {

	h2 {
		color: @highlightGray;
	}

	padding: 0;
	margin-top: 70px;
	background: no-repeat 100% 50%;

	.intro-mask {
		background: linear-gradient(to right, rgba(240,240,240,1) 50%,rgba(230,230,230,0.55) 100%)
	}
	.container {
		padding: 40px 0 25px 0;
		font-size: 18px;
	}
}
