.references-box {
	.reference-arrow-box {
		button {
			height: 100%;
			color: @softGray;
			cursor: pointer;
			background: none;
			border: none;
			outline: none;
			transition: @defaultTransition;
			font-size: 40px;
			width: 100%;
			&:hover {
				color: @mainRed;
			}
		}
	}
	.reference-dots {
		ul {
			padding:0;
			margin:1em auto;
			width: max-content;
			display: flex;
			li {
				list-style: none;
				padding:0;
				margin:0;
				button {
					width: 16px;
					height: 16px;
					margin: 10px;
					background: @splitGray;
					color: transparent;
					font-size: 1px;
					border-radius: 50%;
					transition: @defaultTransition;
					cursor: pointer;
					outline: none;
					border: none;
					&:hover {
						background: @mainGray;
					}
				}
				&.slick-active {
					button {
						background: @mainRed;
					}
				}
			}
		}
	}
}

.reference {
	.reference-image {
		padding-right: 3em;
	}
}
.reference-box {
	border: 1px solid @borderGray;
	padding: 2em 2em 1em 2em;
	position: relative;

	&::before {
		border: 1px solid @borderGray;
		border-top: none;
		border-right: none;
		width: 2em;
		height: 2em;
		background: @white;
		position: absolute;
		right: calc(~"100% - 15px");
		top: calc(~"50% - 18px");
		transform: rotate(45deg);
		content: " ";
	}
}