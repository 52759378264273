.btn {
	border-radius: 0.15em;
	padding: 0.6em 1.4em;
	cursor: pointer;
}

.btn-primary {
	background-color: @mainRed;
	border-color: @mainRed;
}

.btn-outline-primary {
	color: @mainRed;
	border-color: @mainRed;
}

.btn-primary, .btn-outline-primary {
	&:hover, &:focus, &:active {
		background-color: darken(@mainRed, 10%);
		border-color: darken(@mainRed, 10%);
		color: @white;
	}
	&:focus {
		box-shadow: 0 0 0 3px fade(@mainRed, 50%)
	}
	&.disabled, &:disabled {
		background-color: lighten(@mainRed, 20%);
		border-color: lighten(@mainRed, 20%);
	}
}

.form-control {
	border-radius: 0.15em;
	padding:0.2em 0.6em 0 0.6em;
	line-height: 2.4em;
	&:focus {
		border-color: @mainRed;
	}
}
select.form-control {
	&:not([size]):not([multiple]) {
		height: 2.7em;
	}
}

.container {
	@media (min-width: 640px) {
		max-width: 600px;
	}
	@media (min-width: 830px) {
		max-width: 720px;
	}
	@media (min-width: 992px) {
		max-width: 850px;
	}
	@media (min-width: 1050px) {
		max-width: 950px;
	}
}