.breadcrumb {
	background: none;
	padding: 0;
	font-size: 0.9em;

	.breadcrumb-item {
		color: @softGray;
		text-decoration: underline;
		transition: @defaultTransition;
		&:hover {
			color: @mainRed;
			text-decoration: none;
		}
		&.active {
			color: @highlightGray;
			text-decoration: none;
		}
		&+.breadcrumb-item {
			&::before {
				font-family: FontAwesome;
				font-size: 10px;
				content: "\f054";
				position: relative;
				top: -2px;
				color: @softGray;
				padding:0 1em;
			}
		}

	}
}