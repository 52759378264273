.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	font-weight: 800;
	line-height: 1.3em;
}

.h2, h2, .h3, h3, .h4, h4 {
	color: @mainGray;
}

.text-higlight {
	color: @highlightGray;
}

p {
	line-height: 1.5em;
}

body {
	font-family: 'Hind', sans-serif;
	font-size: 18px;
	background: @backgroundGray;
}

header {

	nav {
		padding-top: 10px;
		padding-bottom: 10px;
		border-bottom: 2px solid @splitGray;
		background: @white;
		.logo {
			position: relative;
			@media @min-md {
				left: -48px;
			}
			h1, strong {
				opacity: 0;
				position: absolute;
				top: 25px;
				left: 60px;
				font-size: 26px;
				color: @mainRed;
				line-height: 14px;
				span {
					font-size:14px;
					color: @highlightGray;
					font-weight: 400;
				}
			}
		}
		.navbar-nav {
			.nav-link {
				color: @highlightGray;
				font-size: 18px;
				font-weight: 500;
				border-bottom: 2px solid transparent;
				transition: @defaultTransition;
				&:hover, &:focus {
					color: @mainRed;
				}
				@media @min-md and @max-lmd {
					font-size: 16px;
				}
				@media @max-md {
					border-bottom: none;
					border-left: 2px solid transparent;
					padding: .2em 0 .1em .8em;
					margin-left: 30px;
				}
			}
			.active {
				.nav-link {
					color: @mainRed;
					border-color: @mainRed;
				}
			}
		}
	}
}

footer {
	background: @backgroundGray;
	padding-bottom: 30px;
	font-size: 0.9em;
	.logo {
		position: relative;
		@media @min-md {
			left: -63px;
		}
		img {
			width: 180px;
		}
	}
	hr {
		height: 8px;
		background-image: linear-gradient(270deg, #363333 0%, #a13832 100%);
		margin-bottom: 3em;
	}
	.footer-form {
		h4 {
			font-size: 1em;
		}
		.text-small {
			font-size: 0.9em;
		}
	}
	.footer-contact {
		p {
			font-size: 20px;
			&.text-small {
				font-size: 17px;
			}
			i.fa {
				font-size: 20px;
				float: left;
				margin:2px 8px 0 0;
				&.fa-home {
					margin-bottom: 4em;
				}
			}
			a {
				color: @highlightGray;
				&:hover {
					text-decoration: none;
				}
			}
			a.footer-all-contacts {
				font-size: 16px;
				color: @mainRed;
				text-decoration: underline;
				line-height: 3em;
				&:hover, &:focus {
					text-decoration: none;
				}
			}

		}
	}
}

section {
	background: @white;
	padding: 3em 0;
	@media @max-md {
		padding-top: 2em;
	}
	@media @max-xs {
		padding-top: 1em;
		&.main-section {
			padding-top: 0;
		}
	}

	&.heading {
		background: none;
		padding-top: 90px;
		padding-bottom: 20px;
		font-size: 18px;
		.heading-cta {
			position: relative;
		}
	}
}


.heading-image {
	@media @min-md {
		margin-top: -8em;
	}
	@media @min-sm {
		box-shadow: 0 0 18px rgba(0, 0, 0, 0.12);
	}
	@media @max-xs {
		margin-bottom: 2em;
	}
	img {
		width: 100%;
	}
}

hr {

	border: none;
	height: 2px;
	background-color: @splitGray;
	margin: 0;

	&.hr-strong {
		background-color: @mainRed;
		height: 4px;
		width: 100px;
		margin: 20px 0;
	}

}

.beauty-list {
	ul {
		list-style: none;
		padding: 0;
		li {
			padding-left: 2em;
			&::before {
				font-family: FontAwesome;
				content: '\f0c8';
				color: @mainRed;
				font-size: 0.6em;
				position: relative;
				top: -0.2em;
				margin-left: -1.7em;
				margin-right: 1em;
			}
		}
	}
}